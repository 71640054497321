body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* table {
  -webkit-border-horizontal-spacing: 0;
} */


.container {
  font-family: Arial, Verdana, Geneva, Tahoma, sans-serif;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
